import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SmartFeedJobAlerts from "../components/SmartFeedJobAlerts_WT"

require("es6-promise").polyfill()

const NotFoundPage = () => (
  <Layout>
    <SEO title="Application Confirmation" />
    <>
      <div className="page__header-container page__header-container--application-confirmation-landing">
        <div className="container">
          <div
            className="row justify-content-center page__header page__header--landing application-confirmation-landing__header page__header--application-confirmation-landing"
            id="listing-top"
          >
            <div className="col-12 col-lg-8">
              <h1>Thank you for submitting an application</h1>
              <p>
                We have recieved your application for this role. You'll recieve
                an email shortly confirming your application submission and
                there will be a link to review your application form.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="work-with-us-title-wrap-center">
        <div className="container">
          <h3 className="work-with-us-title">
            <span>See what it's like to work at the St Philips Care</span>
          </h3>
        </div>
      </section>

      <section>
        <div>
          <div className="container deck">
            <div>
              <div className="card-deck no-slider-card-deck">
                <div className="card-deck-list">
                  <div className="card-deck-track">
                    <article className="d-flex card-container">
                      <a
                        href="https://www.woodlandtrust.org.uk/about-us/who-we-are/"
                        target="_self"
                        className="excerpt-link"
                        aria-label="Life at the Woodland trust"
                        tabIndex="0"
                      >
                        <div className="excerpt-card">
                          <div className="excerpt-card__img-box">
                            <img
                              loading="lazy"
                              className="excerpt-card__img-top"
                              src="https://cdn1.accropress.com/Woodland%20Trust/card-images/wt-life.png"
                              title="Life at the Woodland trust"
                              alt="Life at the Woodland trust"
                            />
                          </div>
                          <div className="excerpt-card__body">
                            <p className="excerpt-card__body__category-label">
                              About us
                            </p>
                            <h4 className="excerpt-card__body__title">
                              Life at the Woodland trust
                            </h4>
                            <p className="excerpt-card__body__summary">
                              Discover what it's like to work at the Woodland
                              Trust from the culture to the cause
                            </p>
                          </div>
                        </div>
                      </a>
                    </article>
                    <article className="d-flex card-container">
                      <a
                        href="https://www.woodlandtrust.org.uk/about-us/our-people/"
                        target="_self"
                        className="excerpt-link"
                        aria-label="Th"
                        tabIndex="0"
                      >
                        <div className="excerpt-card">
                          <div className="excerpt-card__img-box">
                            <img
                              loading="lazy"
                              className="excerpt-card__img-top"
                              src="https://cdn1.accropress.com/Woodland%20Trust/card-images/wt-people.png"
                              title="The pople behind our work"
                              alt="The pople behind our work"
                            />
                          </div>
                          <div className="excerpt-card__body">
                            <p className="excerpt-card__body__category-label">
                              About us
                            </p>
                            <h4 className="excerpt-card__body__title">
                              The pople behind our work
                            </h4>
                            <p className="excerpt-card__body__summary">
                              Find out what our people think of life at the
                              trust and what part they play in ensuring a UK
                              rich in native woods and trees
                            </p>
                          </div>
                        </div>
                      </a>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  </Layout>
)

export default NotFoundPage
